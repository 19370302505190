import React, { Component } from 'react';


class Resume extends Component {

render() {
  return (
    <div>
      <br></br>
        <div class="resume">
        </div>
      
    </div>
  );
}

};
export default Resume