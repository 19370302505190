import React from 'react'

function Dog() {
    return (
        <div>
            
            <a href='https://github.com/saleman25/sinatra-project' class='projectl'>
                Repo
            </a>
            <a href='https://drive.google.com/file/d/19Gt7hRs_dXul1vgPlOQlFRoniJwbuoGg/view?usp=sharing' class='projectl'>
                Demo
            </a>
            
            
            <p class='desc'>
            Have you ever just been walking on the street and you see a really cute dog? Do 
            you ever just want to write it down somewhere 
            so you can hold that fond memory forever? Well look no further,
            now you can!
        
            </p>
            <br></br>

            

            


            
        </div>
    )
}

export default Dog
